import React from 'react';
import { Box, Typography } from '@mui/material';

const PythonProject1 = () => {
  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h4" gutterBottom>
        Project 1: Python Example
      </Typography>
      <div>
        <p>This is a collection of Python examples.</p>
        <Typography variant="body1">
          You can view the Python examples on Replit by clicking the link below:
        </Typography>
        <a 
          href="https://replit.com/@jarred5/PortfolioPythonExamples" 
          target="_blank" 
          rel="noopener noreferrer"
        >
          View Python Examples on Replit
        </a>
      </div>
    </Box>
  );
};

export default PythonProject1;
